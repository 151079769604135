// import adjustViewport from './function/adjustViewport'

// import DrawerMenu from './module/DrawerMenu';
// import FormValidator from './module/FormValidator';
import SmoothScroll from './module/SmoothScroll';

const init = () => {
  // (() => {
  //   const headerDrawerMenu = new DrawerMenu({
  //     drawer: '#js-drawer-menu-nav',
  //     drawerOpenBtn: '#js-drawer-open-btn',
  //     drawerCloseBtn: '#js-drawer-close-btn',
  //     drawerBg: '#js-drawer-menu-bg',
  //     attrToggle: 'data-active',
  //   });
  //   headerDrawerMenu.init();
  // })();

  (() => {
    const smoothScroll = new SmoothScroll({
      targets: 'a[href^="#"]',
      fixHeader: '#js-header',
    });
    smoothScroll.init();
  })();

  // (() => {
  //   const formValidator = new FormValidator({
  //     form: '#js-form',
  //     targetInputs: 'input[required], select[required]',
  //     submitBtn: 'button[type="submit"]',
  //   });
  //   formValidator.init();
  // })();

  (() => {
    const menus = [...document.querySelectorAll('.content__main .card__item')]
    const cart = document.querySelector('#cart')
    const cartLists = cart.querySelector('.cart__lists')
    const cartBaseItem = cart.querySelector('.cart__item')
    const cartForm = cart.querySelector('#checkout-form')
    const cartCloseBtn = cart.querySelector('#closebtn')

    let addCartNow = false;

    const remove = (_this) => {
      const parent = _this.closest('.cart__item')
      const name = parent.querySelector('.card__name').textContent
      cartForm.querySelector(`input[name="${name}"]`).remove()
      parent.remove()
      if(cartForm.querySelectorAll('input').length < 2){
        cart.classList.remove('__active')
      }
    }

    const addCart = (_this) => {
      // 要素追加（メニュー）
      const cloneItem = cartBaseItem.cloneNode(true)
      const menuName =  _this.querySelector('.card__name').textContent
      const menuPrice =  _this.querySelector('.card__price').textContent
      cloneItem.querySelector('.card__name').textContent = menuName
      cloneItem.querySelector('.card__price').textContent = menuPrice
      cloneItem.querySelector('.cart__remove').addEventListener('click', (_ev) => {
        remove(_ev.target);
      })
      cartLists.appendChild(cloneItem)
      // 要素追加（input）
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', menuName)
      input.value = menuName
      cartForm.appendChild(input)
    }

    menus.forEach(_menu => {
      _menu.addEventListener('click', (_ev) => {
        addCart(_menu);
        cart.classList.add('__active')
        addCartNow = true;
      })
    });

    cartCloseBtn.addEventListener('click', (_ev) => {
      cart.classList.remove('__active')
    })

    // cartForm.addEventListener('submit', (_ev) => {
    //   const form = _ev.target
    //   _ev.preventDefault();
    //   const checkoutMenus = [...document.querySelectorAll('#cart .cart__item')]
    //   checkoutMenus.forEach(_checkoutMenu => {
    //     const input = document.createElement('input')
    //     const menuName = _checkoutMenu.querySelector('.card__name').textContent
    //     input.setAttribute('type', 'hidden')
    //     input.setAttribute('name', menuName)
    //     input.value = menuName
    //     form.appendChild(input)
    //   });
    //   form.submit();
    // })

  })();
};

window.addEventListener('DOMContentLoaded', () => {
  // adjustViewport()
  init();
});







